import React, { useEffect, useState } from 'react';
import MainMenu from '../components/MainMenu';

const navigationRoutes = [
  { name: 'Početna', route: '/', activeClassName: 'active' },
  { name: 'O nama', route: '/about/', activeClassName: 'active' },
  { name: 'Proizvodi', route: '/products/', activeClassName: 'active' },
  // { name: 'Usluge', route: '/services/', activeClassName: 'active' },
  { name: 'Projekti', route: '/projects/', activeClassName: 'active' },
  { name: 'Kontakt', route: '/contact/', activeClassName: 'active' },
];

const Header = () => {
  const [responsiveMenu, setResponsiveMenu] = useState(false);
  const [shrinkMenu, setShrinkMenu] = useState(false);
  const toggleMenu = () => setResponsiveMenu(!responsiveMenu);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    setShrinkMenu(scrollPosition > 100);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  return (
    <header>
      <MainMenu
        stickyMenu={shrinkMenu}
        responsiveMenu={responsiveMenu}
        routes={navigationRoutes}
        toggleMenu={toggleMenu}
      />
    </header>
  )
}

export default Header;
