import * as React from "react";

import Header from "./header";
import "./layout.css";
import Footer from "./Footer";
import { inject, observer } from 'mobx-react';

const Layout = (observer(({ children, store }) => {
  return (
    <>
      <Header />
      <main onClick={() => store.closeMenu()}>{children}</main>
      <Footer closeMenu={() => store.closeMenu()} />
    </>
  )
}))

export default inject('store')(Layout);
