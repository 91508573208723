import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import { Link } from "gatsby";
import { inject, observer } from 'mobx-react';
import { useLocation } from '@reach/router';
import { StaticImage } from 'gatsby-plugin-image';

const MainMenu = (
  observer(({ stickyMenu, store, routes }) => {
    const [anim, setAnim] = useState(true)
    const logoRef = useRef()
    const location = useLocation();
    useEffect(() => {
      if (store.logoLoaded) {
        setAnim(false)
      }
      store.setLogoLoaded()
    }, [])
    return (
      <>
        <div className={`menu-wrapper ${stickyMenu ? 'sticky-menu' : 'exit'}`}>
          <div className="m-container">
            <div ref={logoRef} className={`logo-container ${anim ? 'logo-container-animation' : ''}`}>
              <Link to='/'>
                <StaticImage loading='lazy' className="logo-main" alt="MRI zastita Piperski logo" src='../../images/piperskilogo2-11.jpg' />
                <p className="logo-label">MRI Shielding Solutions</p>
              </Link>
            </div>
            <nav className="main-menu">
              {
                routes.map(nav => (
                  <Link activeClassName="active" exact to={nav.route}>
                    {nav.name}
                  </Link>
                ))
              }
            </nav>
          </div>
        </div>
        <div className="responsive-menu">
          <div id="menuToggle">
            <input
              onClick={() => store.toggleMenu()}
              type="checkbox"
              className={store.menuState ? "toggle-menu" : ""}
            />
            <span></span>
            <span></span>
            <span></span>
            <nav id="menu">
              {
                routes.map(nav => (
                  <Link onClick={location.pathname === nav.route ? store.closeMenu : () => null} activeClassName={nav.activeClassName} exact to={nav.route}>
                    {nav.name}
                  </Link>
                ))
              }
            </nav>
          </div>
          <div className='menu-logo'>
            <StaticImage loading='lazy' className="" alt="MRI zastita Piperski logo" src='../../images/piperskilogo2-11.jpg' />
          </div>
          <div className='empty' />
        </div>
      </>
    )
  }))

export default inject('store')(MainMenu);
