import React from 'react';
import './style.css';
import LogoWhite from '../../images/piperskilogo_white.png';
import PinIcon from '../../images/pin-red.png';
import PhoneIcon from '../../images/phone-red.png';
import { Link } from "gatsby";

const Footer = ({closeMenu}) => {
  return (
    <footer onClick={closeMenu} className="footer-main">
      <div className="footer-inner-main container">
        <div className="footer-widget logo-widget">
          <div className="footer-logo-wrapper">
            <img loading='lazy' alt='Piperski Logo' src={LogoWhite} />
          </div>
          <div className="footer-logo-caption">
            <p>MRI Shielding Solutions</p>
          </div>
        </div>
        <div className="footer-inner container">
          <div className="footer-widget">
            <div className="widget-title">
              <p>Navigacija</p>
            </div>
            <nav className="widget-data">
              <Link to='/'>Početna</Link>
              <Link to='/about'>O nama</Link>
              <Link to='/products'>Proizvodi</Link>
              <Link to='/projects'>Projekti</Link>
              <Link to='/contact'>Kontakt</Link>
            </nav>
          </div>
          <div className="footer-widget">
            <div className="widget-title">

              <p>Usluge</p>
            </div>
            <div className="widget-data">
            <Link to='/products/#services-separator'>Popravka</Link>
              <Link to='/products/#services-separator'>Održavanje</Link>
              <Link to='/products/#services-separator'>Prepravka</Link>
              <Link to='/products/#services-separator'>Rasveta</Link>
              <Link to='/products/#services-separator'>Rešenja</Link>
              <Link to='/products/#services-separator'>Adaptacija</Link>
            </div>
          </div>
          <div className="footer-widget">
            <div className="widget-title">
              <p>Kontakt</p>
            </div>
            <div className="widget-contact">
              <div className="widget-contact-row">
                <div className="widget-contact-icon">
                  <img loading='lazy' alt='Map pin' src={PinIcon} />
                </div>
                <div className="widget-contact-text">
                  <p>Beograd, Srbija</p>
                </div>
              </div>
              <div className="widget-contact-row">
                <div className="widget-contact-icon">
                  <img loading='lazy' alt='Phone' src={PhoneIcon} />
                </div>
                <div className="widget-contact-text">
                  <p>+381 63 862 1652</p>
                  <p>+381 64 1256 220</p>
                </div>
              </div>
              {/* <div className="widget-contact-row">
                <div className="widget-contact-icon">
                  <img loading='lazy' alt='Mail' src={MailIcon} />
                </div>
                <div className="widget-contact-text">
                  <p>piperskidoo@piperski.com</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-bar">
        <p className="bottom-center container">© 2022 Piperski D.O.O. Sva prava zadržana</p>
      </div>
    </footer>
  );
}

export default Footer;
